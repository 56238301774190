export default {
  A: "#10a35c",
  R: "#10fc03",
  N: "#10eb14",
  D: "#10eb14",
  C: "#1010ff",
  Q: "#10f10e",
  E: "#10f10e",
  G: "#109d62",
  H: "#10d52a",
  I: "#1054ab",
  L: "#107b84",
  K: "#10ff10",
  M: "#109768",
  F: "#108778",
  P: "#10e01f",
  S: "#10d52a",
  T: "#10db24",
  W: "#10a857",
  Y: "#10e619",
  V: "#105fa0",
  B: "#10eb14",
  X: "#10b649",
  Z: "#10f10e"
};
