export default {
  A: "#ffafaf",
  R: "#6464ff",
  N: "#10ff10",
  D: "#ff1010",
  C: "#ffff10",
  Q: "#10ff10",
  E: "#ff1010",
  G: "#ff10ff",
  H: "#6464ff",
  I: "#ffafaf",
  L: "#ffafaf",
  K: "#6464ff",
  M: "#ffafaf",
  F: "#ffc810",
  P: "#ff10ff",
  S: "#10ff10",
  T: "#10ff10",
  W: "#ffc810",
  Y: "#ffc810",
  V: "#ffafaf",
  B: "#ffffff",
  X: "#ffffff",
  Z: "#ffffff"
};
