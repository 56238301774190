export default {
  A: "#ffa510",
  B: "#ffffff",
  C: "#108100",
  D: "#ff1010",
  E: "#ff1010",
  F: "#108100",
  G: "#ffa510",
  H: "#ff10ff",
  I: "#108100",
  J: "#ffffff",
  K: "#ff1010",
  L: "#108100",
  M: "#108100",
  N: "#ff10ff",
  O: "#ffffff",
  P: "#108100",
  Q: "#ff10ff",
  R: "#ff1010",
  S: "#ffa510",
  T: "#ffa510",
  U: "#ffffff",
  V: "#108100",
  W: "#108100",
  X: "#ffffff",
  Y: "#108100",
  Z: "#ffffff",
  Gap: "#ffffff"
};
