export default {
  A: "#ffa510",
  B: "#ffffff",
  C: "#108010",
  D: "#ff1010",
  E: "#ff1010",
  F: "#1010ff",
  G: "#ffa510",
  H: "#ff1010",
  I: "#108010",
  J: "#ffffff",
  K: "#ff1010",
  L: "#108010",
  M: "#108010",
  N: "#ffffff",
  O: "#ffffff",
  P: "#ffa510",
  Q: "#ffffff",
  R: "#ff1010",
  S: "#ffa510",
  T: "#ffa510",
  U: "#ffffff",
  V: "#108010",
  W: "#1010ff",
  X: "#ffffff",
  Y: "#1010ff",
  Z: "#ffffff",
  Gap: "#ffffff"
};
