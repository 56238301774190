var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "autocomplete" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.search,
          expression: "search",
        },
      ],
      staticClass: "input-group-text",
      attrs: { type: "text" },
      domProps: { value: _vm.search },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          },
          _vm.onChange,
        ],
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            ) {
              return null
            }
            return _vm.onArrowDown.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            return _vm.onArrowUp.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.onEnter.apply(null, arguments)
          },
        ],
        click: function ($event) {
          _vm.filterResults()
          _vm.isOpen = true
        },
      },
    }),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        staticClass: "autocomplete-results",
        attrs: { id: "autocomplete-results" },
      },
      [
        _vm.isLoading
          ? _c("li", { staticClass: "loading" }, [
              _vm._v("\n      Loading results...\n    "),
            ])
          : _vm._l(_vm.results, function (result, i) {
              return _c(
                "li",
                {
                  key: i,
                  staticClass: "autocomplete-result",
                  class: { "is-active": i === _vm.arrowCounter },
                  on: {
                    click: function ($event) {
                      return _vm.setResult(result.id)
                    },
                  },
                },
                [
                  _c("span", { attrs: { title: result.stats } }, [
                    _vm._v("\n        " + _vm._s(result.name) + "\n      "),
                  ]),
                ]
              )
            }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }