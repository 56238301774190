export default {
  A: "#BBBBBB",
  B: "#808080",
  C: "#ffff10",
  D: "#ff1010",
  E: "#ff1010",
  F: "#ff10ff",
  G: "#964B10",
  H: "#10FFFF",
  I: "#BBBBBB",
  J: "#ffffff",
  K: "#10FFFF",
  L: "#BBBBBB",
  M: "#BBBBBB",
  N: "#108010",
  O: "#ffffff",
  P: "#964B10",
  Q: "#108010",
  R: "#10FFFF",
  S: "#108010",
  T: "#108010",
  U: "#ffffff",
  V: "#BBBBBB",
  W: "#ff10ff",
  X: "#808080",
  Y: "#ff10ff",
  Z: "#808080",
  Gap: "#808080"
};
