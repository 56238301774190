export default {
  A: "#80a0f0",
  R: "#f01505",
  N: "#10ff10",
  D: "#c048c0",
  C: "#f08080",
  Q: "#10ff10",
  E: "#c048c0",
  G: "#f09048",
  H: "#15a4a4",
  I: "#80a0f0",
  L: "#80a0f0",
  K: "#f01505",
  M: "#80a0f0",
  F: "#80a0f0",
  P: "#ffff10",
  S: "#10ff10",
  T: "#10ff10",
  W: "#80a0f0",
  Y: "#15a4a4",
  V: "#80a0f0",
  B: "#ffffff",
  X: "#ffffff",
  Z: "#ffffff"
};
