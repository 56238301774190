var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "pink section", staticStyle: { display: "flex" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "headerOptions",
              staticStyle: { "margin-left": "auto", "padding-top": "10px" },
            },
            [
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { title: "Go to RiboVision 2.0 documentation" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      staticStyle: { float: "right" },
                      attrs: { id: "aboutButton" },
                      on: {
                        click: function ($event) {
                          return _vm.goToAboutPage()
                        },
                      },
                    },
                    [_vm._v("About")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { attrs: { title: "Start an interactive guide" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-dark",
                    staticStyle: { float: "right" },
                    on: {
                      click: function ($event) {
                        return _vm.startUploadTour()
                      },
                    },
                  },
                  [_vm._v("User Upload Tour")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-dark",
                    staticStyle: { float: "right" },
                    on: {
                      click: function ($event) {
                        return _vm.startTour()
                      },
                    },
                  },
                  [_vm._v("RiboVision Tour")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("v-tour", {
        attrs: {
          name: "myTour",
          steps: _vm.steps,
          callbacks: _vm.myCallbacks,
          options: { highlight: true },
        },
      }),
      _vm._v(" "),
      _c("v-tour", {
        attrs: {
          name: "userUploadTour",
          steps: _vm.userUploadSteps,
          callbacks: _vm.myCallbacks,
          options: { highlight: true },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-top": "10px" } }, [
      _c("span", { staticClass: "title" }, [
        _vm._v("RiboVision 2.0: Advanced Visualization of RNA molecules "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }