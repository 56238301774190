export default {
  A: "#77dd88",
  B: "#ffffff",
  C: "#99ee66",
  D: "#55bb33",
  E: "#55bb33",
  F: "#9999ff",
  G: "#77dd88",
  H: "#5555ff",
  I: "#66bbff",
  J: "#ffffff",
  K: "#ffcc77",
  L: "#66bbff",
  M: "#66bbff",
  N: "#55bb33",
  O: "#ffffff",
  P: "#eeaaaa",
  Q: "#55bb33",
  R: "#ffcc77",
  S: "#ff4455",
  T: "#ff4455",
  U: "#ffffff",
  V: "#66bbff",
  W: "#9999ff",
  X: "#ffffff",
  Y: "#9999ff",
  Z: "#ffffff",
  Gap: "#ffffff"
};
